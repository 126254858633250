import React, { useState, useEffect } from "react";
import { Link, graphql } from "gatsby";
import LayoutMain from "../components/layout-main";
import SEO from "../components/seo";
import {getArticles} from "../articleResource"
import Moment from 'react-moment';
import titleize from 'underscore.string/titleize';

import styled from 'styled-components'

const ListStyled = styled.ol`
  list-style: none;
  margin-left: var(--spacing-0);
  margin-right: var(--spacing-0);
  padding: var(--spacing-0);
  margin-bottom: var(--spacing-8);
  list-style-position: outside;
  list-style-image: none;
`

const BlogIndex = ({ data, location, pageContext: { apiPosts } }) => {
  const siteTitle = data.site.siteMetadata?.title;

  const [postsFromApi, setPostsFromApi] = useState(apiPosts);

  useEffect(() => {
    (async () => {
      const artickes = await getArticles();
      setPostsFromApi(artickes);
    })();
  }, [])

  if (!postsFromApi || postsFromApi.length === 0) {
    return (
      <LayoutMain location={location} title={siteTitle}>
        <SEO title="Blog" />
        <p>
          No blog posts found.
        </p>
      </LayoutMain>
    );
  }

  return (
    <LayoutMain location={location} title={siteTitle}>
      <SEO title="Blog" />
      <ListStyled>
        {postsFromApi.map((post) => {
          return (
            <li key={post.id}>
              <article
                className="post-list-item"
                itemScope
                itemType="http://schema.org/Article"
              >
                <header>
                  <h2>
                    <Link to={`blog/${post.slug}`} itemProp="url">
                      <span itemProp="headline">{titleize(post.title)}</span>
                    </Link>
                  </h2>
                  <small><Moment date={post.date} format={'MMM DD, YYYY'}/></small>
                </header>
                <section>
                  <p>
                    {post.description || post.excerpt}
                  </p>
                  {/*<p
                    dangerouslySetInnerHTML={{
                      __html: post.frontmatter.description || post.excerpt,
                    }}
                    itemProp="description"
                  />*/}
                </section>
              </article>
            </li>
          );
        })}
      </ListStyled>
    </LayoutMain>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
